<template>
  <label for="terms" class="terms no-select clicable" id="terms">
    <input
      type="checkbox"
      data-testid="terms"
      id="terms"
      v-model="terms"
      :disabled="disabled"
    />
    <span @click.stop.prevent="!disabled ? (terms = !terms) : (terms = terms)">
      {{ $t("i_accept_the") }}
    </span>
    <a v-bind:href="terms_url" data-testid="terms-link" target="_blank">{{
      $t("terms_of_use")
    }}</a>
    &amp;
    <a v-bind:href="privacy_url" data-testid="policy-link" target="_blank">{{
      $t("privacy_policy")
    }}</a>
  </label>
</template>

<script>
export default {
  name: "Terms",
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    terms: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.disabled ? false : this.value;
      }
    },
    terms_url() {
      return this.$root.config.company_terms || "#";
    },
    privacy_url() {
      return this.$root.config.company_privacy_policies || "#";
    }
  }
};
</script>

<style scoped>
.terms {
  font-size: 13pt;
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}
</style>
